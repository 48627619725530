import React from "react"
import { Helmet } from "react-helmet"

import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import * as styles from "../../styles/projects.module.css"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

export default function Projects({ data }) {
  const projects = data.projects.nodes
  const contact = data.contact.siteMetadata.contact

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content="Patrick Oldman's portfolio websites."
        />
        <title>Patrick Oldman | Portfolio</title>
      </Helmet>
      <div className={styles.portfolio}>
        <h2>Portfolio</h2>
        <h3>Websites I created</h3>
        <p>
          I have used a range of technologies for all of my sites, using both
          Front and Back-End libraries as well as relational and non-relational
          database systems.
          <br />
          <br />
          The sites are responsive and created with a mobile first design in
          mind.
        </p>
        <div className={styles.projects}>
          {projects.map(project => (
            <Link to={`/projects/${project.frontmatter.slug}`} key={project.id}>
              <div className={styles.project}>
                <GatsbyImage
                  image={getImage(
                    project.frontmatter.mockup.childImageSharp.gatsbyImageData
                  )}
                  alt="Picture"
                />
                <h3>{project.frontmatter.title}</h3>
                <p>{project.frontmatter.stack}</p>
              </div>
            </Link>
          ))}
        </div>
        <p>
          Like what you see? Email me at <strong>{contact}</strong> to get in
          touch
        </p>
      </div>
    </Layout>
  )
}

// export page query
export const query = graphql`
  query ProjectsPage {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___priority, order: ASC }
    ) {
      nodes {
        frontmatter {
          slug
          stack
          title
          priority
          mockup {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        id
      }
    }
    contact: site {
      siteMetadata {
        contact
      }
    }
  }
`
